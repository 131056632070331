:root {
  /* font-family: 'Open Sans'; */
  font-family: 'Montserrat';
  --font-base: font-family;
  --primary-color: #5258FB;
  /* --secondary-color: #0c2b41; */
  --secondary-color: #eff5fc;
  --black-color: #15172F;
  --lightGray-color: #e4e4e4;
  --gray-color: #A28F9D;
  --brown-color: #46364a;
  --white-color: #FFFFFF;
  --light: 300;
  --bold: 500;
  --black: 800; 
  --background-img: 'white';
}

*{
  font-family: 'Montserrat';
  margin-bottom: 0;
}

/* BG STYLES */

.dark-bg {
  background-color: var(--black-color);
  background-size: cover;
}

.light-bg {
  background-image: url('assets/bg/SphereBG.png');
  background-color: rgba(139, 143, 188, 0.13);
  background-size: cover;
}

.purple-bg {
  background-image: url('assets/bg/SphereDark.png');
  background-color: rgba(82, 88, 251, 1);
  background-size: cover;
}

.white-bg {
  background-color: white;
}

/* TEXT OPTIONS */

p {
  font-family: 'Nunito';
  padding-bottom: 2rem;
}

a {
  text-decoration: underline;
}

h1 {
  font-family: 'Montserrat';
  font-size: 25px;
  margin-bottom: 20px;
  margin-top: 0;
}

h2 {
  font-family: 'Montserrat';
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 10px;
}

h3 {
  font-family: 'Montserrat';
  font-size: 15px;
  margin-top: 0;
  margin-bottom: 0px;
}

ul {
  text-indent: 1rem;
  font-size: 10px;
}

.large-h1 {
  font-size: 20px;
}

.large-p {
  font-size: 17px;
}

.dark-text {
  color: var(--black-color);
}

.light-text {
  color: var(--primary-color)
}

.nunito {
  font-family: 'Nunito';
}

.montserrat {
  font-family: 'Montserrat';
}

.no-decoration {
  text-decoration: none;
}


.white-text {
  color: var(--white-color);
}

.capitalize {
  text-transform: capitalize;
}

.light {
  font-weight: 300;
}

.regular {
  font-weight: 400; 
}

.bold {
  font-weight: 700;
}

.landing-p {
  font-size: 12px;
}

/* ICON OPTIONS */
.header-icon {
  font-size: 30px;
}

/* BTN OPTIONS */
.primary-btn {
  background-color: var(--primary-color);
  color: var(--white-color);
  font-family: 'Montserrat';
  font-weight: 800;
  text-transform: none;
}

.primary-btn:hover {
  background-color: #3338be;

}
.primary-btn:focus {
  background-color: var(--primary-color);
  
}
.primary-btn:active {
  background-color: var(--primary-color);
  
}

.secondary-btn {
  background-color: white;
  color: var(--primary-color);
  font-family: 'Montserrat';
  font-weight: 800;
  text-transform: none;
}
.secondary-btn:hover {
  background-color: rgb(230, 230, 230)

}
.secondary-btn:focus {
  background-color: white;
  
}
.secondary-btn:active {
  background-color: white;
  
}

.rounded {
  border-radius: 10px;
}

/* IMG OPTIONS */

.fit-screen {
  max-width: 150px;
  max-height: 80px;
}

.fit-screen-main {
  max-height: 200px;
  margin-bottom: 1rem;
}


/* NAV STYLES */

.nav-change {
  position: fixed;
  z-index: 100;
}

.logo-nav {
  height: 50px;
  margin-left: 15px;
  margin-top: 10px;
}

.nav-height {
  height: 10px;
}

/* .sidenav {
  background-color: var(--black-color);
} */


/* GENERAL STYLES */

.l-card-50-s-100 {
  max-width: 100%;
  height: auto;
}

.full-width{
  width: 100%;
}

.margin-left {
  margin-left: 20px !important;
}

.padding-top {
  padding-top: 50px;
}

.s-padding-top {
  padding-top: 25px;
}

.padding-bottom {
  padding-bottom: 50px;
}

.x-padding-top {
  padding-top: 100px;
}

.margin-top {
  margin-top: 50px;
}

.x-margin-top {
  margin-top: 100px;
}

.no-margin {
  margin: 0;
}

.no-padding {
  padding: 0;
}

.no-padding-or-margin {
  padding: 0;
  margin: 0;
}

.xs-margin-bottom {
  margin-bottom: 5px;
  margin-top: 0;
  padding: 0;
}

.s-margin-bottom {
  margin-bottom: 20px;
  margin-top: 0;
  padding: 0;
}

.margin-bottom {
  margin-bottom: 50px;
}

.bottom {
  vertical-align: bottom !important;
}

/* JS STYLES */

.carousel {
  min-height: 500px;
  justify-content: center;
  margin: auto;
}

.carousel-img {
  max-width: 400px;
}

.card-content {
  margin: 0;
  padding: 0;
}


.card-text {
  margin: auto;
  padding: auto;
}

.card {
  margin: 0;
  padding: 10;
}

.card-img {
  vertical-align: middle !important;
  align-items: center !important;
  justify-content: center !important;
  padding-top: 10px;
}
.small {
  margin: 0;
  padding: 0;
  height: 250px !important;
}

.n-red-shadow {
  background-color: #D93031;
  box-shadow: 0px 5px 10px 1px rgba(255, 65, 75, 0.4);
  width: 60%; 
}
.n-red-shadow:hover {
  background-color: #7e2a2a;
  box-shadow: 0px 5px 10px 1px rgba(255, 65, 75, 0.4);
}
.n-red-shadow:focus {
  background-color: #D93031;
  box-shadow: 0px 5px 10px 1px rgba(255, 65, 75, 0.4);
}
.n-red-shadow:active {
  background-color: #D93031;
  box-shadow: 0px 5px 10px 1px rgba(255, 65, 75, 0.4);
}

.n-red {
  background-color: #D93031;
  box-shadow: 0px 5px 10px 1px rgba(255, 65, 75, 0.4);
}
.n-red:hover {
  background-color: #7e2a2a;
  box-shadow: 0px 5px 10px 1px rgba(255, 65, 75, 0.4);
}
.n-red:focus {
  background-color: #D93031;
  box-shadow: 0px 5px 10px 1px rgba(255, 65, 75, 0.4);
}
.n-red:active {
  background-color: #D93031;
  box-shadow: 0px 5px 10px 1px rgba(255, 65, 75, 0.4);
}

.n-red-m {
  background-color: #D93031;
  box-shadow: 0px 5px 10px 1px rgba(255, 65, 75, 0.4);
  width: 30%; 
}
.n-red-m {
  background-color: #D93031;
  box-shadow: 0px 5px 10px 1px rgba(255, 65, 75, 0.4);
  width: 70% !important;
}
.n-red-m:hover {
  background-color: #7e2a2a;
  box-shadow: 0px 5px 10px 1px rgba(255, 65, 75, 0.4);
}
.n-red-m:focus {
  background-color: #D93031;
  box-shadow: 0px 5px 10px 1px rgba(255, 65, 75, 0.4);
}
.n-red-m:active {
  background-color: #D93031;
  box-shadow: 0px 5px 10px 1px rgba(255, 65, 75, 0.4);
}

.n-green {
  background-color: #30D960;
}

.n-yellow {
  background-color: #f7f594;
  box-shadow: 0px 5px 10px 1px rgba(189, 187, 67, 0.4);
}
.n-yellow:hover {
  background-color: #c0be61;
  box-shadow: 0px 5px 10px 1px rgba(189, 187, 67, 0.4);
}

.n-yellow:active {
  background-color: #f7f594;
  box-shadow: 0px 5px 10px 1px rgba(189, 187, 67, 0.4);
}

.n-yellow:focus {
  background-color: #f7f594;
  box-shadow: 0px 5px 10px 1px rgba(189, 187, 67, 0.4);
}

.interactive-link {
  color: white;
}

.drop-shadow {
  box-shadow: 0px 2px 0px 0px rgba(0,0,0,0.2);
}

.active-profile {
  box-shadow: 0px 0px 30px 5px #0FD9BA;
}

.profile-card {
  margin-top: 20px;
  max-width: 500px;
  min-width: 220px;
}

.carousel-min {
  min-height: 600px;
  min-width: 250px;
  margin-top: -200px;
}

.intro-gif {
  margin-top: 20px;
}

.carousel-card {
  min-width: 250px;
  width: 100%;
  min-height: 350px;
}

.full-width {
  width: 100%
}

.w-70 {
  width: 70%;
}

.tall-btn {
  height: 50px;
}

.l-left-m-center {
  align-items: center;
  text-align: center;
}

.card-btn-left {
  margin-top: -25px;
  float: left !important;
}

.card-btn-right {
  margin-top: -25px;
  float: left !important;
  margin-left: 10px;
}

.init-padding {
  padding-top: 1px;
  padding-bottom: 1px;
}

.nav-height {
  height: 60px;
}

.ml-20 {
  margin-left: 20px;
}

.mw-1000 {
  max-width: 350px;
}

.test-txt {
  font-size: 100%;
}

.video-r{
  width: 100%;
  height: auto !important;
  margin-top: 3rem;
}


@media only screen and (min-width: 425px) {
  .card-btn-right {
      float: left !important;
      margin-left: 10px;
  }
  .video-r{
      width: 360px;
      height: 202px !important;
      margin-top: 3rem;
  }
}

@media only screen and (min-width: 600px) {
  .n-red-m {
      max-width: 30%; 
  }

  .large-h1 {
    font-size: 25px;
  }

  .fit-screen-main {
    max-height: 300px;
  }

  ul {
    text-indent: 2rem;
    font-size: 12px;
  }
}

@media only screen and (min-width: 1000px) {

  .large-h1 {
    font-size: 40px;
  }

  .fit-screen-main {
    max-height: 400px;
  }

  ul {
    text-indent: 4rem;
    font-size: 16px;
  }
}

@media only screen and (min-width: 1024px) {
  h1 {
      font-size: 60px;
  }
  h2 {
      font-size: 35px;
  }

  h5 {
      font-size: 25px;
  }
  p {
      font-size: 20px;
  }

  .large-h1 {
    font-size: 40px;
  }

  .large-font {
      font-size: 120%;
  }
  .card-heading {
      line-height: 15px;
  }
  .l-left-m-center {
      align-items: left;
      text-align: left;
  }
  .nav-height {
      height: 70px;
  }
  .w-40-m-and-up {
      width: 40%; 
  }
  .line-height-nrm {
      line-height: normal;
  }
  .video-r{
      width: 480px;
      height: 270px !important;
      margin-top: 2rem;
  }
  .l-card-50-s-100 {
    max-width: 50%;
    height: auto;
  }
}

@media only screen and (min-width: 1600px) {
  .video-r{
      width: 720px;
      height: 405px !important;
      margin-top: 2rem;
  }

}

@media only screen and (min-height: 1024px) {
  .fill-screen{
      height: 80vh;

  }

}
